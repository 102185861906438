import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseDevicesTable } from "src/components/Paradise/ParadiseDevicesTable"
import { useParadiseDevicesFilter } from "src/components/Paradise/useParadiseDevicesFilter"
import { useFetchParadiseDashboardDevices } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { IParadiseDeviceListFilter } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"

const LIMIT = 10

export function ParadiseHomeDevices({ homeId }: { homeId: string }) {
  const { filter, setFilter, offset, setOffset, sortedFilter } =
    useParadiseDevicesFilter()

  const { sortValue, setSortValue } = useSortParam()

  const hasFiltered = Object.values(filter).some((f) => !!f)

  const fetchedHomeDevices = useFetchParadiseDashboardDevices({
    filter: {
      home_id: homeId,
      limit: LIMIT,
      offset,
      device_id: filter.devices_id || undefined,
      description: filter.devices_description || undefined,
      device_mac: filter.devices_mac || undefined,
      active: !hasFiltered || undefined,
      installed_firmware: filter.devices_firmware || undefined,
      hardware_version: filter.devices_hardwareVersion ?? undefined,
      or_tags: filter.devices_tag ?? undefined,
      owner_id: filter.devices_owner || undefined,
      offline: filter.devices_offline ?? undefined,
      sort:
        (sortValue?.id as IParadiseDeviceListFilter["sort"]) || "device_mac",
      sort_by: sortValue?.order || "asc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  return (
    <div>
      <ParadiseDetailsSection title="Devices" />

      <ParadiseDevicesTable
        devices={fetchedHomeDevices.data?.devices ?? []}
        offset={offset}
        limit={LIMIT}
        setOffset={setOffset}
        filter={filter}
        setFilter={setFilter}
        sortedFilters={sortedFilter}
        sort={sortValue}
        setSort={setSortValue}
        totalCount={fetchedHomeDevices.data?.total_count}
        isLoading={fetchedHomeDevices.isPreviousData}
        error={{
          hasError: fetchedHomeDevices.isError,
          title: fetchedHomeDevices.error?.message,
        }}
        disableColumnHiding
      />
    </div>
  )
}
